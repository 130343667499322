import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedMessageHTML from 'components/Core/FormattedMessageHTML';
import Card from 'components/Shared/Card/Card';
import ErrorAlertIcon from 'components/Shared/Icons/ErrorAlertIcon';
import WarningAlertIcon from 'components/Shared/Icons/WarningAlertIcon';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { colors, fontSize } from 'styles/cp';
import { ActivityInfo } from 'types/SplunkLogs';

const splunkReporter = SplunkReporter.getInstance();

const logger = 'components/Shared/MessageCard/MessageCard';
export type Props = {
  status?: 'error' | 'info' | 'warn';
  header?: string;
  text: string;
  messageValues?: { [key: string]: string };
  errorMessageIconPosition?: 'start' | 'end' | 'center';
  activityInfo?: ActivityInfo;
};
export interface State {}

class MessageCard extends Component<Props, State> {
  errorRef: React.RefObject<any>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.errorRef = React.createRef();
  }

  componentDidMount() {
    if (this.errorRef.current) {
      window.scrollTo({ behavior: 'smooth', top: this.errorRef.current.offsetTop });
    }
    const { status, text, activityInfo } = this.props;
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'view',
      action: 'render',
      activityInfo: {
        status: status === 'error' ? status : 'success',
        activityObject: 'messageCard',
        message: text,
        ...activityInfo,
      },
    });
  }

  render() {
    const { status = 'error', header, text, messageValues = {} } = this.props;
    const color =
      status === 'error' ? colors.darkError : status === 'info' ? colors.intuit_blue : colors.warn;
    const exclamationColor = status === 'info' ? colors.white : colors.gray;
    return (
      <div ref={this.errorRef} className="error-message-root">
        <Card borderRadius="4px">
          <div className="error-message-wrapper">
            <div className="error-message-icon">
              {status === 'error' ? (
                <ErrorAlertIcon color={color} height={23} width={23} />
              ) : (
                <WarningAlertIcon
                  color={color}
                  height={24}
                  width={24}
                  exclamationColor={exclamationColor}
                />
              )}
            </div>
            <div className="error-message-content">
              {header && (
                <span className="error-message-header">
                  <FormattedMessage id={header} defaultMessage="Error" />
                </span>
              )}
              <span className="error-message-text">
                <FormattedMessageHTML
                  id={text}
                  defaultMessage="Payment wasn’t successful. Try again or contact your seller."
                  values={messageValues}
                />
              </span>
            </div>
          </div>
        </Card>
        {/*language=SCSS*/}
        <style jsx>{`
          .error-message-root {
            margin-bottom: 20px;
          }
          .error-message-wrapper {
            display: flex;
            flex-direction: row;
            border: solid 1px ${color};
            border-radius: 4px;
            padding: 20px;
          }

          .error-message-icon {
            display: flex;
            flex-direction: column;
            justify-content: ${this.props.errorMessageIconPosition || 'center'};
          }

          .error-message-content {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
          }
          .error-message-header {
            font-size: ${fontSize.sm};
            color: ${color};
            //margin-bottom: 6px;
          }

          .error-message-text {
            font-size: ${fontSize.xxs};
          }

          .error-message-text {
            :global(a) {
              color: ${colors.blue};
              text-decoration: none;
            }
          }
        `}</style>
      </div>
    );
  }
}

export default MessageCard;
